<template>
   <div class="home mobile" 
   :style="{backgroundImage: 'url(' + staticUrl+'design/shoujiduan/Landingpage.png' + ')'}">
        <div class="home-top">
            <img src="../../assets/images/logo-new.png" alt="">
            <div class="title">
                <h3><span style="display: none;">中国</span>家居</h3>
                <p>产业智联网</p>
            </div>
        </div>
        <div class="home-title">欢迎使用</div>
        <div class="home-middle">
            <div @click="goto('/mobile/login')" class="button">立即登录</div>
            <div @click="goto('/mobile/register')" style="margin-top:24px;" class="button">马上注册</div>
        </div>
        <div class="home-footer">邀请内测版</div>
   </div>
</template>
<script>
    import { IMG_URL_PRE,STATIC_URL_PRE } from '@/config';
    import { shopDetailId } from '@/service/mobile';
    import { getCompBaseInfoById } from '@/service/company';
    export default {
        components: {},
        data() {
            return {
                imgUrl:IMG_URL_PRE,
                staticUrl:STATIC_URL_PRE,
                item:{},
            }
        },
        async created() {
            // this.refreshItems();
            // this.comp = await getCompBaseInfoById(this.$route.params.compid);
            // document.title = "金融支持下的备料服务调研";
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            goto(path){
                this.$router.push(path);
            },
            refreshItems() {
                console.log('refreshItems');
                shopDetailId({product_no:this.$route.params.id,share_user_id:this.$route.params.userid,}).then(rst => {
                    this.item = rst;
                    console.log(this.item);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="./style.css"></style>
<style scoped lang="less">
.home {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
}
.home-top {
    width: 100%;
    text-align: center;
    padding-top: 6%;
    >img {
        width:64px;height:64px;
        margin-bottom:9px;
    }
    .title {
        h3 {
            width:80px;
            margin:0 auto;
            height:25px;
            font-size:18px;
            font-weight:bold;
            line-height:25px;
            color:#5074EE;
            border-bottom: 1px solid #5074EE;
        }
        p {
            height:20px;
            font-size:14px;
            font-weight:400;
            line-height:20px;
            color:#5074EE;
            margin-bottom: 0;
        }
    }
}
.home-title {
    width: 100%;
    text-align: center;
    height:28px;
    font-size:20px;
    font-weight:bold;
    line-height:28px;
    color: #ffffff;
    position: absolute;
    top: 31%;
}
.home-middle {
    width: 67.2%;
    margin: 0 auto 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 32%;
    .button {
        width: 100%;
        text-align: center;
        height:40px;
        line-height: 40px;
        background:rgba(255,255,255,1);
        border:1px solid rgba(112,112,112,1);
        border-radius:36px;
        font-size:16px;
        color: #808080;
        cursor: pointer;
    }
}
.home-footer {
    width: 100%;
    text-align: center;
    height:17px;
    font-size:12px;
    font-weight:400;
    line-height:16px;
    color:#FAFBFF;
    position: absolute;
    bottom: 2%;
}
</style>